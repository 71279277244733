// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'qa-na',
  production: false,
  region: 'NA',
  appUrl: 'https://https://device-health.qa.zonarsystems.net/',

  apiBase: {
    url: 'https://api-qa.zonarsystems.net/gtcx-device-health-api/v1'
  },

  here: {
    apiKeyV3: 'zRHQYeMWPTut1VrQIirpsuKQqaxgeY7dImB-CE0ND_0'
  },

  geocoderApi: {
    url: 'https://zonar-nonprod-qa.apigee.net'
  },

  coreEntityApiBase: {
    url: 'https://api-qa.zonarsystems.net'
  },

  userPreferencesApiBase: {
    url: 'https://api-qa.zonarsystems.net/alert/v1'
  },

  //  TODO: Setup your application's authentication variables: see
  //  https://gitlab.com/ZonarSystems/tools/pattern-library/-/tree/master/projects/zonar-ui-auth
  // for instructions on how to do that.
  authEnv: 'qa',

  auth: {
    clientID: 'y1bCIM4FB0zZr9fjbmnbhbsSFmB7cBrJ',
    domain: 'zonar-qa.auth0.com',
    audience: 'http://apiqa.zonarsystems.net/',
    // These come from the Core APIs as your application is built.
    applicationId: 'b7b79766-d4d3-4341-a462-df5e9ada14d1',
    defaultZonarRole: '17e6ee56-9782-4a67-85cc-5c12195cf900',
    useRefreshTokens: true
  },

  pendo: {
    url: 'https://cdn.pendo.io/agent/static/'
  },

  devCycle: {
    clientId: 'dvc_client_9f74f791_f400_4ad0_ac43_e08cdf703ee9_6cf5b62',
    tabletEventFeature: 'tablet-events-restriction',
    tcuEventFeature: 'tcu-events-restriction',
    rfidEventFeature: 'rfid-events-restriction',
    deviceViewFeature: 'device-overview-restriction'
  },

  googleTagManager: {
    id: 'GTM-PM9R42P',
    auth: '',
    environment: ''
  },

  //TODO: Setup your application's datadog app (see empty box readme)
  datadog: {
    applicationId: 'ba51facc-0ff9-4652-b17b-00cfd8744d44',
    clientToken: 'pub9dd0ad51e98fbbf5460bfc5ac520bdff',
    site: 'us5.datadoghq.com',
    tagEnvironment: 'qa'
  },

  i18n: {
    supportedLanguages: [
      'en-US',
      'de-DE',
      'en-GB',
      'fr-FR',
      'es-ES',
      'it-IT',
      'en-CA'
    ],
    defaultLanguage: 'en-US'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
